import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { WrapXs } from '$components/Wraps';
import Heading3 from '$components/Heading3';
import FadeInSection from '$components/FadeInSection';
import ListItem from './ListItem';
import { BlockListBlockProps } from './types';
import Markdown from '$components/Markdown';

const OrderedList = styled.ol`
  list-style: none;
  max-width: 60em;
  counter-reset: list;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  ${scalingSize('margin-top', 32, 80)}
  ${scalingSize('margin-bottom', 32, 80)}
`;

const BlockListBlock: React.FC<BlockListBlockProps> = ({ list }) => (
  <WrapXs>
    <FadeInSection>
      <OrderedList>
        {list.map((item) => (
          <ListItem key={item.Heading}>
            <div>
              <Heading3 isList>{item.Heading}</Heading3>
              <Markdown content={item.Content} />
            </div>
          </ListItem>
        ))}
      </OrderedList>
    </FadeInSection>
  </WrapXs>
);

export default BlockListBlock;
