import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';

const StyledListItem = styled.li`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em 0;
  align-items: start;
  ${scalingSize('margin-bottom', 16, 40)};
  font-size: 1em;
  line-height: 1.75;
  margin: 1em 0 2em;
  color: var(--color-body-text);

  @media screen and (min-width: 680px) {
    grid-template-columns: auto 3fr;
  }

  &:before {
    counter-increment: list;
    content: counters(list, '.', decimal-leading-zero);
    color: var(--color-slate4);
    font-family: var(--font-osynlig);
    font-feature-settings: 'ss01' on;
    font-weight: normal;
    ${scalingSize('font-size', 40, 96, 1.430555556)};
    border-bottom: 0.375rem solid var(--color-citrus);
    width: fit-content;
    line-height: 1;
    padding-bottom: 0.7rem;

    @media screen and (min-width: 680px) {
      margin-bottom: 1.5rem;
      text-align: right;
      ${scalingSize('margin-right', 40, 96, 1.430555556)};
    }
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    margin: 1em 0 2em;
    color: var(--color-body-text);
  }
`;

const ListItem: React.FC = ({ children }) => <StyledListItem>{children}</StyledListItem>;

export default ListItem;
